<template>
  <div>
    <p class="has-text-right field">
      <router-link
        class="has-text-info has-text-weight-semibold"
        :to="{ name: 'auth-login' }"
      >
        or log in
      </router-link>
    </p>
    <div class="field">
      <input
        placeholder="Name"
        type="text"
        v-model="form.name"
        :class="{ 'is-danger': errors.has('name') }"
        v-validate.disable="'required'"
        name="name"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('name')">
        {{ errors.first('name') }}
      </span>
    </div>
    <div class="field">
      <input
        placeholder="Job title"
        type="text"
        v-model="form.jobTitle"
        :class="{ 'is-danger': errors.has('jobTitle') }"
        v-validate.disable="'required'"
        name="jobTitle"
        data-vv-as="job title"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('jobTitle')">
        {{ errors.first('jobTitle') }}
      </span>
    </div>
    <div class="field">
      <input
        placeholder="Email address"
        type="text"
        v-model="form.email"
        :class="{ 'is-danger': errors.has('email') }"
        v-validate.disable="'required|email'"
        name="email"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('email')">
        {{ errors.first('email') }}
      </span>
    </div>
    <div class="field">
      <input
        placeholder="Password"
        type="password"
        v-model="form.password"
        v-validate.disable="{ required: true, min: 8, regex }"
        name="password"
        ref="password"
        class="input"
        :class="{ 'is-danger': errors.has('password') }"
      />
      <span class="help has-text-danger" v-if="errors.has('password')">
        {{ errors.first('password') }}
      </span>
    </div>
    <div class="field">
      <input
        placeholder="Confirm password"
        type="password"
        v-model="form.password_confirmation"
        v-validate.disable="{ required: true, confirmed: form.password }"
        data-vv-as="password"
        name="password_confirmation"
        class="input"
        :class="{ 'is-danger': errors.has('password_confirmation') }"
      />
      <span
        class="help has-text-danger"
        v-if="errors.has('password_confirmation')"
      >
        {{ errors.first('password_confirmation') }}
      </span>
    </div>
    <span class="is-size-7">
      <span>Password rules</span>
      <a
        v-tippy="{ placement: 'top', html: '#password-tooltip' }"
        class="icon is-small has-text-info"
      >
        <i class="fal fa-info-circle" />
      </a>
    </span>
    <PasswordTooltip id="password-tooltip" style="display: none" />
    <div class="content">
      <p class="help">
        By creating an account for the Trade Portal, you agree to our terms of
        service which will be presented to you when you first login.
      </p>
    </div>
    <div class="field is-grouped is-grouped-right">
      <button class="button is-info" @click.prevent="setUser">Continue</button>
    </div>
  </div>
</template>

<script>
import PasswordTooltip from '../password/PasswordTooltip'
export default {
  name: 'AuthSignupUser',
  components: { PasswordTooltip },
  data: () => ({
    form: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      jobTitle: '',
      source: 'signup'
    }
  }),
  methods: {
    async setUser() {
      const valid = await this.$validator.validate()

      if (!valid) return
      this.$store.commit('auth/signup/SET_USER', this.form)
      this.$emit('done')
    }
  },
  computed: {
    regex() {
      return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]).{8,}$/g
    }
  }
}
</script>
